<template>
  <!-- 方案详情页面 -->
  <div class="program-page">
    <div class="banner">
      <img :src="$IMG_BASE_URL + banner.img" alt=""  @load="bannerLoadOver = true">
      <div class="banner-text fc-white" :class="bannerLoadOver? 'animate': ''">
        <p class="title fw-bold">{{ banner.title }}</p>
        <p class="fs-24 subtitle">{{ banner.subTitle }}</p>
      </div>
    </div>
    <!-- 页面锚点 -->
    <a-anchor class="nav" @click="handleAnchorClick" :target-offset="targetOffset" :offsetTop="offsetTop">
      <a-anchor-link v-for="(item, index) in anchorData" :key="index" :href="item.href" :title="item.title" />
      <div class="service-btn fc-white bg-blue fs-18" @click.stop="isShowCommitP=true">
        <a-icon type="message" class="mr-10" /><span>服务咨询</span>
      </div>
    </a-anchor>

    <!-- 内容展示部分 -->
    <div class="content-wrap">
      <!-- 方案介绍 -->
      <div class="introduce-part" id="introduceId">
        <div class="w">
          <h1 class="num-title t-center"><i>01</i><span>{{ introduce.title }}</span></h1>
          <div class="content" v-show="introduce.img">
            <img :src="$IMG_BASE_URL + introduce.img" alt="">
            <div class="introduce-text">
              <div class="text fs-20 fc-title line-break">
                {{ introduce.text }}
              </div>
              <ul class="tags">
                <li class="fs-20 fc-content"><span class="bg-white">{{ introduce.tag?.[0] }}</span></li>
                <li class="fs-20 fc-content"><span class="bg-white">{{ introduce.tag?.[1] }}</span></li>
                <li class="fs-20 fc-content"><span class="bg-white">{{ introduce.tag?.[2] }}</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="bg-text">
          <img src="@/assets/program/方案bg文字.png" alt="">
        </div>
      </div>
      <!-- 行业痛点 -->
      <div class="pain-part" id="painId">
        <div class="w">
          <h1 class="num-title t-center"><i>02</i><span>核心痛点</span></h1>
          <div class="content">
            <div class="item" :class="{ 'item-reverse': item.type === 'bottom' }" v-for="(item, index) in painList"
              :key="'pain' + index">
              <div class="pain-img"><img :src="$IMG_BASE_URL + item.img" alt=""></div>
              <div class="pain-text" :style="{ backgroundImage: `url(${$IMG_BASE_URL + item.bg})` }">
                <p class="fs-22 fc-title mb-15 fw-bold">{{ item.title }}</p>
                <p class="fs-18 fc-content">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 核心优势 -->
      <div class="core-part" id="coreId">
        <div class="w">
          <h1 class="num-title t-center"><i>03</i><span>核心优势</span></h1>
          <div class="content">
            <div class="item-wrap">
              <div class="item" :style="{backgroundImage: `url(${$IMG_BASE_URL+item.img})`}" v-for="(item, index) in coreList" :key="'core'+index">   
                <p class="fs-22 fc-title fw-bold">{{ item.title }}</p>
                <hr>
                <p class="fs-18 fc-content">{{ item.text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 业务流程 -->
      <div class="process-part" id="processId">
        <div class="w">
          <h1 class="num-title t-center"><i>04</i><span>业务架构</span></h1>
          <div class="content">
            <img style="width:1200px;" :src="processData"/>
          </div>
        </div>
      </div>
      <!-- 合作客户 -->
      <div class="case-part" id="caseId">
        <div class="w">
          <h1 class="num-title t-center"><i>05</i><span>合作客户</span></h1>
          <div class="swiper-box" v-if="caseList.length>0">
            <!-- 合作客户 - swiper -->
            <swiper :options="caseSwiperOptions" ref="mySwiper">
              <swiper-slide class="item-wrap" v-for="(item, index) in caseList" :key="'case' + index">
                <!-- 图文混合case -->
                <div v-if="item.type === 'text'" class="item case-mixin-item bg-white">
                  <div class="case-img"><img :src="$IMG_BASE_URL + item.img" alt=""></div>
                  <div class="case-text">
                    <p class="fs-26 fc-title mb-20 fw-bold">{{ item.title }}</p>
                    <p class="fs-16 fc-content line-break">
                      {{ item.text }}</p>
                    <p class="rate-p">
                      <span><i class="fc-title fw-bold">{{ item.tag1Val || '' }}</i> <img v-if="item.tag1Icon" :src="$IMG_BASE_URL+item.tag1Icon" alt=""> <br> <i class="fc-content">{{ item.tag1Name }}</i></span>
                      <span><i class="fc-title fw-bold">{{ item.tag2Val || '' }}</i> <img v-if="item.tag2Icon" :src="$IMG_BASE_URL+item.tag2Icon" alt=""> <br> <i class="fc-content">{{ item.tag2Name }}</i></span>
                      <span><i class="fc-title fw-bold">{{ item.tag3Val || '' }}</i> <img v-if="item.tag3Icon" :src="$IMG_BASE_URL+item.tag3Icon" alt=""> <br> <i class="fc-content">{{ item.tag3Name }}</i></span>
                    </p>
                  </div>
                </div>
                <!-- 图片类型case -->
                <div class="item case-img-item" v-else><img :src="$IMG_BASE_URL + item.img" alt="" /></div>
              </swiper-slide>
            </swiper>
            <!-- 合作客户 - navigation -->
            <div v-show="caseList.length >= 2" class="case-pre-btn pre-btn arrow-btn">
              <a-icon type="left" />
            </div>
            <div v-show="caseList.length >= 2" class="case-next-btn next-btn arrow-btn">
              <a-icon type="right" />
            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- 留言弹窗 -->
     <message-form class="common-message-form" v-model="isShowCommitP" :mask="true" @close="isShowCommitP = false"></message-form>
  </div>
</template>
  
<script>
import MessageForm from '@/components/MessageForm'
import { Anchor, Carousel, Icon } from 'ant-design-vue'
const { VUE_APP_API } = process.env 
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import api from '@/api'
export default {
  components: {
    [Anchor.name]: Anchor,
    [Anchor.Link.name]: Anchor.Link,
    [Carousel.name]: Carousel,
    [Icon.name]: Icon,
    swiper,
    swiperSlide,
    MessageForm
  },
  data() {
    return {
      bannerLoadOver: false, // banner是否加载完毕
      isShowCommitP: false, // 留言弹窗
      targetOffset: null,
      offsetTop: 0,
      anchorData: [ // 锚点数据
        { title: '方案介绍', href: '#introduceId' },
        { title: '核心痛点', href: '#painId' },
        { title: '核心优势', href: '#coreId' },
        { title: '业务流程', href: '#processId' },
        { title: '合作客户', href: '#caseId' },
      ],
      caseSwiperOptions: { // 合作客户swiper配置项
        slidesPerView: 1,
        loop: false,
        navigation: {
          nextEl: '.case-next-btn',
          prevEl: '.case-pre-btn',
        },
      },
      banner: {},
      introduce: {},
      painList: [],
      coreList: [],
      caseList: [],
      pChannelId: 0,
      channelIds: [],
      processData: {} 
    }
  },
  methods: {
    handleAnchorClick(e, link) {
      e.preventDefault();
      var srcolls = document.getElementById(link.href.split('#')[1]);
      srcolls.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    // banner
    async getBanner() {
      const contents = (await api.getContentsList({ channelId: this.channelIds[0] })).contents
      this.banner = { title: contents[0].title, img: contents[0].bannerImg, subTitle: contents[0].subTitle }
    },
    // 方案介绍
    async getIntroduce() {
      const contents = (await api.getContentsList({ channelId: this.channelIds[1] })).contents
      this.introduce = { title: contents[0].title, img: contents[0].introduceImg, text: contents[0].introduceText, tag: [contents[0].introduceTag1, contents[0].introduceTag2, contents[0].introduceTag3] }
    },
    // 行业痛点
    async getPainList() {
      const contents = (await api.getContentsList({ channelId: this.channelIds[2] })).contents
      contents.forEach(item => {
        this.painList.push({ title: item.title, text: item.painText, img: item.painImg, type: item.type, bg: item.painBg })
      })
    },
    // 核心优势
    async getCoreList() {
      const contents = (await api.getContentsList({ channelId: this.channelIds[3] })).contents
      contents.forEach(item => {
        this.coreList.push({ title: item.title, text: item.coreText, img: item.coreImg })
      });
    },
    // 业务流程
    async getProcessList() {
      const contents = (await api.getContentsList({ channelId: this.channelIds[4] })).contents
      this.processData = `${VUE_APP_API}${contents[0].imageUrl}`.replace('/api', '')
    },
    // 合作客户
    async getCaseList() {
      const contents = (await api.getContentsList({ channelId: this.channelIds[5] })).contents
      contents.forEach(item => {
        this.caseList.push({ ...item, title: item.title, text: item.caseText, img: item.caseImg, goodRate: item.goodRate, errRate: item.errRate, cost: item.cost, type: item.type })
      });
      this.caseSwiperOptions.loop = this.caseList.length > 1

    },
    async init() {
      const { children } = await api.getChannel({ channelId: this.pChannelId })
      children.map((item, index) => {
        this.channelIds[index] = item.id
      })
      this.getBanner()
      this.getIntroduce()
      this.getPainList()
      this.getCoreList()
      this.getProcessList()
      this.getCaseList()
    }
  },
  mounted() {
    this.targetOffset = window.innerHeight / 3;
  },
  created() {
    const pChannelId = this.$route.query.id
    if (!pChannelId) return
    this.pChannelId = pChannelId
    this.init()
  },
}
</script>
  
<style lang="less" scoped>
@import url('~@/styles/m.less');
@import url('~@/styles/animate.less');
@import url(./index.less);
</style>